import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    }
  },
  routes: [
    {
      path: '/',
      name: 'dashboard',
      redirect: 'categories',
    },
    {
      path: '/categories',
      component: {
        render(c) {
          return c('router-view')
        }
      },
      children: [
        {
          path: '',
          name: 'categories',
          component: () => import('@/views/Categories.vue'),
          meta: {
            pageTitle: 'Категории',
            breadcrumb: [
              {
                text: 'Меню',
                active: true,
              },
              {
                text: 'Категории',
                active: true,
              },
            ],
            requiresLoggedIn: true,
          },
        },
        {
          path: 'add',
          name: 'categoryAdd',
          component: () => import('@/views/CategoryAdd.vue'),
          meta: {
            pageTitle: 'Категория',
            breadcrumb: [
              {
                text: 'Меню',
                active: true,
              },
              {
                text: 'Категории',
                active: true,
              },
              {
                text: 'Категория',
                active: true,
              },
            ],
            requiresLoggedIn: true,
          },
        },
        {
          path: ':id',
          name: 'categoryEdit',
          component: () => import('@/views/CategoryEdit.vue'),
          meta: {
            pageTitle: 'Категория',
            breadcrumb: [
              {
                text: 'Меню',
                active: true,
              },
              {
                text: 'Категории',
                active: true,
              },
              {
                text: 'Категория',
                active: true,
              },
            ],
            requiresLoggedIn: true,
          },
        },
      ]
    },
    {
      path: '/positions',
      component: {
        render(c) {
          return c('router-view')
        }
      },
      children: [
        {
          path: '',
          name: 'positions',
          component: () => import('@/views/Positions.vue'),
          meta: {
            pageTitle: 'Позиции',
            breadcrumb: [
              {
                text: 'Меню',
                active: true,
              },
              {
                text: 'Позиции',
                active: true,
              },
            ],
            requiresLoggedIn: true,
          },
        },
        {
          path: 'add',
          name: 'positionAdd',
          component: () => import('@/views/PositionAdd.vue'),
          meta: {
            pageTitle: 'Позиция',
            breadcrumb: [
              {
                text: 'Меню',
                active: true,
              },
              {
                text: 'Позиции',
                active: true,
              },
              {
                text: 'Позиция',
                active: true,
              },
            ],
            requiresLoggedIn: true,
          },
        },
        {
          path: ':id',
          name: 'positionEdit',
          component: () => import('@/views/PositionEdit.vue'),
          meta: {
            pageTitle: 'Позиция',
            breadcrumb: [
              {
                text: 'Меню',
                active: true,
              },
              {
                text: 'Позиции',
                active: true,
              },
              {
                text: 'Позиция',
                active: true,
              },
            ],
            requiresLoggedIn: true,
          },
        },
      ],
    },
    {
      path: '/settings',
      name: 'settings',
      component: () => import('@/views/Settings.vue'),
      meta: {
        pageTitle: 'Настройки',
        breadcrumb: [
          {
            text: 'Меню',
            active: true,
          },
          {
            text: 'Настройки',
            active: true,
          },
        ],
        requiresLoggedIn: true,
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresLoggedIn) && !store.getters['auth/isLoggedIn']) {
    return next({ name: 'login' })
  }

  next()
})

// router.afterEach(() => {
//   const appLoading = document.getElementById('loading-bg')

//   if (appLoading) {
//     appLoading.style.display = 'none'
//   }
// })

export default router
