export default {
  loginEndpoint: '/auth/login',
  refreshEndpoint: '/auth/refresh',
  logoutEndpoint: '/auth/logout',
  getUserEndpoint: '/auth/me',

  getMenuEndpoint: '/menu',
  updateMenuEndpoint: '/menu',

  getCategoriesEndpoint: '/menu/categories',
  createCategoryEndpoint: '/menu/categories',
  getCategoryEndpoint: '/menu/categories',
  updateCategoryEndpoint: '/menu/categories',
  deleteCategoryEndpoint: '/menu/categories',

  getPositionsEndpoint: '/menu/items',
  createPositionEndpoint: '/menu/items',
  getPositionEndpoint: '/menu/items',
  updatePositionEndpoint: '/menu/items',
  deletePositionEndpoint: '/menu/items',

  tokenType: 'Bearer',

  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
