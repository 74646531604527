import jwtDefaultConfig from './jwtDefaultConfig'
import store from '@/store'

export default class JwtService {
  // Will be used by this service for making API calls
  Ins = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken()

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      this.handleError,
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => response,
      this.handleError,
    )
  }

  handleError = (error) => {
    const { config, response } = error
    const originalRequest = config

    if (response?.config?.url.includes(this.jwtConfig.refreshEndpoint)) {
      return Promise.reject(error)
    }

    if (response?.status === 401 && !response?.config?.url.includes(this.jwtConfig.refreshEndpoint)) {
      if (!this.isAlreadyFetchingAccessToken) {
        this.isAlreadyFetchingAccessToken = true

        this.refreshToken()
          .then(r => {
            this.isAlreadyFetchingAccessToken = false

            this.setToken(r.data.access)
            this.setRefreshToken(r.data.refresh)

            this.onAccessTokenFetched(r.data.access)
          })
          .catch(() => {
            this.removeToken()
            this.removeRefreshToken()

            store.commit('auth/UPDATE_ACCESS_TOKEN', null)

            return Promise.reject(error)
          })
      }
      const retryOriginalRequest = new Promise(resolve => {
        this.addSubscriber(accessToken => {
          originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
          resolve(this.axiosIns(originalRequest))
        })
      })
      return retryOriginalRequest
    }

    return Promise.reject(error)
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  removeToken() {
    localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
  }

  removeRefreshToken() {
    localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      refresh: this.getRefreshToken(),
    })
  }

  logout() {
    return this.axiosIns.post(this.jwtConfig.logoutEndpoint, {
      refresh: this.getRefreshToken(),
    })
  }

  getUser() {
    return this.axiosIns.get(this.jwtConfig.getUserEndpoint)
  }

  getMenu() {
    return this.axiosIns.get(this.jwtConfig.getMenuEndpoint)
  }

  updateMenu(id, ...args) {
    return this.axiosIns.put(`${this.jwtConfig.updateMenuEndpoint}/${id}`, ...args)
  }

  getCategories() {
    return this.axiosIns.get(this.jwtConfig.getCategoriesEndpoint)
  }

  createCategory(...args) {
    return this.axiosIns.post(this.jwtConfig.createCategoryEndpoint, ...args)
  }

  getCategory(id) {
    return this.axiosIns.get(`${this.jwtConfig.getCategoryEndpoint}/${id}`)
  }

  updateCategory(id, ...args) {
    return this.axiosIns.put(`${this.jwtConfig.updateCategoryEndpoint}/${id}`, ...args)
  }

  deleteCategory(id) {
    return this.axiosIns.delete(`${this.jwtConfig.deleteCategoryEndpoint}/${id}`)
  }

  getPositions(...args) {
    return this.axiosIns.get(this.jwtConfig.getPositionsEndpoint, ...args)
  }

  createPosition(...args) {
    return this.axiosIns.post(this.jwtConfig.createPositionEndpoint, ...args)
  }

  getPosition(id) {
    return this.axiosIns.get(`${this.jwtConfig.getPositionEndpoint}/${id}`)
  }

  updatePosition(id, ...args) {
    return this.axiosIns.patch(`${this.jwtConfig.updatePositionEndpoint}/${id}`, ...args)
  }

  deletePosition(id) {
    return this.axiosIns.delete(`${this.jwtConfig.deletePositionEndpoint}/${id}`)
  }
}
