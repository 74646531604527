import useJwt from '@/auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {
    accessToken: useJwt.getToken(),
    user: null,
  },
  getters: {
    isLoggedIn: state => !!state.accessToken,
    currentUser: state => state.user,
  },
  mutations: {
    UPDATE_ACCESS_TOKEN(state, val) {
      state.accessToken = val
    },
    UPDATE_USER(state, val) {
      state.user = val
    },
  },
  actions: {
    async login({ commit }, credentials) {
      try {
        const res = await useJwt.login(credentials)

        const { access, refresh } = res.data

        useJwt.setToken(access)
        useJwt.setRefreshToken(refresh)

        commit('UPDATE_ACCESS_TOKEN', access)
      } catch(err) {
        throw err
      }
    },
    async logout({ commit }) {
      try {
        await useJwt.logout()

        useJwt.removeToken()
        useJwt.removeRefreshToken()

        commit('UPDATE_ACCESS_TOKEN', null)
        commit('UPDATE_USER', null)
      } catch(err) {
        throw err
      }
    },
    async getUser({ commit }) {
      try {
        const res = await useJwt.getUser()
  
        commit('UPDATE_USER', res.data)
      } catch(err) {
        commit('UPDATE_USER', null)
      }
    },
  },
}
